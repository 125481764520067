import axios from 'axios';
import Cookies from 'js-cookie';
import { auth } from './auth';
import { pushDataLayer } from './pushDataLayer';

const API_ENDPOINT = 'https://api.barbace.nl/v1/e/collect';
const CID = process.env.GATSBY_COL_CONFIGURATION_ID;

interface UserParams {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
}

declare global {
    interface Window {
        Cookiebot: {
            consent: {
                marketing: boolean;
            };
        },
        gtag:any,
        fbq:any
    }
}

export const metaPixel = ({ eventType, eventId }:any) => {

    const pixelId = process.env.GATSBY_FB_PIXEL_ID

    if (typeof window === 'undefined') return;

    if (!window.fbq) {
        const pixelScript = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq.disablePushState = true
            fbq.allowDuplicatePageViews = true
            fbq('init', '${pixelId}');
        `;
        const scriptTag = document.createElement('script');
        scriptTag.innerHTML = pixelScript;
        document.head.appendChild(scriptTag);
    }

    if (window.fbq) {
        window.fbq('track', `${eventType}`, {}, {eventID: `${eventId}`});
    }

};

export const trackEvent = async ({ eventType, userParams }: Props) => {

    const eventTypeMap = {
        'page_view': {
            facebook: 'PageView'
        },
        'lead': {
            facebook: 'Lead'
        },
        'contact': {
            facebook: 'Contact'
        },
        'schedule': {
            facebook: 'Schedule'
        }
    }

    const EID = crypto.randomUUID();
    const UID = Cookies.get('uid');
    const FBP = Cookies.get('_fbp');
    
    if (typeof window !== 'undefined' && window.Cookiebot.consent.marketing) {
        metaPixel({
            eventId: EID,
            eventType: eventTypeMap[eventType].facebook
        })

        if (window.gtag) {
            if (eventType === 'schedule') {
                window.gtag('event', 'conversion', {
                    send_to: `AW-16781588304/nKG-CObAgewZENDuisI-`
                });
            }
        }

        const payload: { [key: string]: any } = {
            et: eventType,
            eid: EID,
            cid: CID,
            fu: window.location.href
        };

        if (UID) payload.uid = UID;

        if (userParams?.firstName) payload.ufn = userParams.firstName;
        if (userParams?.lastName) payload.uln = userParams.lastName;
        if (userParams?.email) payload.uem = userParams.email;
        if (userParams?.phone) payload.uph = userParams.phone;
        if (FBP) payload.fbp = FBP;

        let isRetried = false;

        const sendEvent = async () => {
            try {
                let response = await axios.post(
                    API_ENDPOINT,
                    payload,
                    { withCredentials: true }
                );
        
                if (response.data?.uid) {
                    Cookies.set('uid', response.data.uid, { expires: 365, secure: true, sameSite: 'Strict' });
                }
                return response;
            } catch (error) {
                if (error.response && (error.response.status === 401 || error.response.status === 403) && !isRetried) {
                    isRetried = true; // Set isRetried before retrying
                    await auth(); // Call getToken to refresh token
                    return sendEvent(); // Retry sendEvent after refreshing the token
                } else {
                    throw error;
                }
            }
        };
        return await sendEvent(); // Call sendEvent initially
    }
};

interface Props {
    eventType: 'page_view' | 'lead' | 'contact' | 'schedule';
    userParams?: UserParams;
}