import "./src/styles/global.sass";
import "@fontsource-variable/open-sans";
import "@fontsource-variable/raleway";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { trackEvent } from './src/utils/col';
import { auth } from "./src/utils/auth";
import React from "react";

declare global {
    interface Window {
        Cookiebot: {
            consent: {
                marketing: boolean;
            };
        };
    }
}

let isInitialLoad = true

export function onRouteUpdate() {
    if (isInitialLoad) {
        isInitialLoad = false
    } else {
        trackEvent({
            eventType:'page_view'
        });
    }
};

export function onInitialClientRender() {
    if (typeof window !== "undefined") {
        auth();
        window.addEventListener("CookiebotOnAccept", () => {
            trackEvent({
                eventType:'page_view'
            });
        });
    }
};